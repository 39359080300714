$(function(){
    $('#mv').slick({
        autoplay: true,
        dots: false,
        arrows: true,
        fade: true,
        slidesToShow: 1,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        speed: 2000
    });
    $('#mv-sp').slick({
        autoplay: true,
        dots: false,
        arrows: true,
        fade: true,
        slidesToShow: 1,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        speed: 2000
    });

    $('[accordion-trigger]').click(function() {
      $(this).children('[accordion-toggle]').slideToggle();
      $(this).find('a').toggleClass('active');
      return false;
    });

    getNewsList('/news/list.json', 'news');
});